const convertTimestampToSeconds = (timestamp) => {
  const [minutes, seconds] = timestamp.split(':').map(Number);
  return minutes * 60 + seconds;
};

const extractTimestamps = (description) => {
  const lines = description.split('\n');
  return lines.filter((line) => /^\d{1,2}:\d{2}/.test(line)); // Matches lines with time format XX:XX or X:XX
};

export { convertTimestampToSeconds, extractTimestamps };
