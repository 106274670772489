import React from 'react';
import { Modal } from 'react-responsive-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import 'react-responsive-modal/styles.css';

const NetworkModal = ({ isOpen, onClose, children, titleAndLogo, width = '23vw', height = '95vh' }) => {
  const modalStyles = {
    modal: {
      width: width,
      maxWidth: '500px',
      height: height,
      borderRadius: '20px',
      padding: '0px',
      position: 'fixed',
      right: isOpen ? '0px' : '-25vw', // Adjust this if the modal width changes
      top: '0px',
      overflow: 'auto',
      transition: 'right 0.5s ease-in-out',
      backgroundColor: "rgba(17,23,57,0.98)"
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)'
    }
  };

  const { name, logo } = titleAndLogo || { name: '', logo: '' };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      center={false}
      styles={modalStyles}
      showCloseIcon={false} // Disable default close icon
    >
      <div className="flex items-center justify-between p-4 ">
        <div className="flex flex-row items-center">
            <img src={logo} alt={name} className="w-10 h-10" />
            <h2 className="drawer-title text-white text-xl  ml-4">{name}</h2>
        </div>
        <button
          className="text-white text-lg font-bold bg-transparent rounded-full "
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faCircleXmark} size='2xl'/>
        </button>
      </div>
      <div>
        {children} {/* Render the modal's children here */}
      </div>
    </Modal>
  );
};

export default NetworkModal;
