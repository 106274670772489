import React from "react";
import BackgroundDot from "../components/BackgroundDot";
import Navbar from "../components/Navbar";
import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";



function AppLayout({ children }) {
    return (
        <div className="h-[100vh]">
            <Header />
            <Navbar />
            <BackgroundDot />
            {children}
            {/* <Footer/> */}
        </div>
    );
}

export default AppLayout;