import React from 'react'
import './Voices.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import VideoList from '../../components/VoicesComponents/VideoList';
import videoData from '../../internal-data/videoData.json';

const data = videoData.reverse();

export default function Voices() {

  return (
    <div id="voices" className=" flex flex-col justify-between">
        
        <div className="voices-title mt-0 flex flex-col  flex-grow">
                    <div className="text-center">
                        <div className="flex flex-col sm:flex-row items-left justify-left pl-20 mt-28">
                            <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl bh-logo text-white">🎤 BlockHunters</h1>
                            <p className="text-custom-orange ml-0 sm:ml-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold bh-logo">Voices</p>
                        </div>
                    </div>
                        <VideoList videos={data} />
        </div>
    </div>
  )
}
