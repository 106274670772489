// VideoList.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../componentscss/VideoList.css";

const VideoList = ({ videos }) => {
  const navigate = useNavigate();

  const handleClick = (video) => {
    // Navigate to the video page and pass the video data as state
    // navigate(`/voices/ep-${video.id}`);
    navigate(`/voices/ep-${video.id}`);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1  gap-6">
        {videos.map((video) => (
          <div
            key={video.id}
            className="gradient-border-box bg-transparent rounded-lg shadow-lg p-4 flex cursor-pointer"
            onClick={() => handleClick(video)}
          >
            <img
              src={video.thumbnail}
              alt={video.title}
              className="w-24 h-24 object-cover rounded-lg"
            />
            <div className="ml-4 flex-1">
              <h2 className="video-list-title">{video.title}</h2>
              <p className="video-list-meta mt-6">Posted on {video.date}</p>
              <p className="video-list-meta">Duration: {video.duration}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoList;
