import React from "react";

function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center h-[100vh]">
      <h1 className="text-4xl font-bold text-custom-orange">404</h1>
      <p className="text-2xl text-white">Page Not Found</p>
    </div>
  );
}

export default NotFound;