import React, { useState, useRef, useEffect } from "react";
import { Menu, ProductItem, MenuItem } from "../ui/navbar-menu";
import Logo from "../../assets/logo.png";
import "../componentscss/Header.css";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa"; 

const NavbarMenu = () => {
  const [activeItem, setActiveItem] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); 

  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={`w-full fixed z-50 flex justify-between items-center px-4 lg:px-10 ${isScrolled ? "floating-dock-scrolled" : "floating-dock"}`}>

      <div>
        <Link to="/">
          <img src={Logo} alt="Block Hunters logo" className="h-7 sm:h-10" />
        </Link>
      </div>

      {/* Hamburger Icon for Mobile Menu */}
      <div className={`lg:hidden z-50 ${isMenuOpen ? 'hidden' : ''}`}>
        <button onClick={toggleMenu} className="text-white">
          {!isMenuOpen && <FaBars size={24} />}
        </button>
      </div>

      {/* Desktop Menu */}
      <div className="hidden lg:flex main-menu">
        <Menu setActive={setActiveItem} className="main-menu">
          <Link to="/">
            <MenuItem item="Home" setActive={setActiveItem} active={false}>
              <ProductItem
                title="Home Product"
                description="This is the home product description."
                href="/"
                src="/path-to-your-image/home.png"
              />
            </MenuItem>
          </Link>
          <Link to="/voices">
            <MenuItem item="Voices" setActive={setActiveItem} active={false}>
            </MenuItem>
          </Link>
          <Link to="/contact-us">
            <MenuItem item="Contact" setActive={setActiveItem} active={false}>
            </MenuItem>
          </Link>
          {/* <Link to="/about">
            <MenuItem item="About" setActive={setActiveItem} active={false}>
            </MenuItem>
          </Link> */}
        </Menu>
      </div>

      {/* Mobile Menu (Full Screen) */}
      <div
        className={`lg:hidden fixed top-0 left-0 w-full h-screen bg-dark-blue-background bg-opacity-100 transition-transform transform ${
          isMenuOpen ? "translate-y-0" : "-translate-y-full"
        } z-40`}
      >
        
        <div className="flex justify-end p-4">
          <button onClick={toggleMenu} className="text-white z-50">
            <FaTimes size={30} />
          </button>
        </div>

        
        <div className="flex justify-center mb-4">
          <Link to="/">
            <img src={Logo} alt="Block Hunters logo" className="h-10" />
          </Link>
        </div>

        
        <div className="container mx-auto border-t border-gray-700 my-4 w-3/4"></div>

        {/* Menu Links (Centered) */}
        <div className="flex flex-col items-center justify-center h-full space-y-6 text-white text-lg">
          <Link to="/" onClick={toggleMenu}>Home</Link>
          <Link to="/voices" onClick={toggleMenu}>Voices</Link>
          <Link to="/contact-us" onClick={toggleMenu}>Contact Us</Link>
          {/* <Link to="/about" onClick={toggleMenu}>About</Link> */}
        </div>
      </div>
    </div>
  );
};

export default NavbarMenu;
