import { useEffect, useState, useRef } from 'react';

export const useYouTubePlayer = (embedId) => {
  const [playerReady, setPlayerReady] = useState(false);
  const playerRef = useRef(null);

  // Function to initialize the YouTube player
  const initializePlayer = () => {
    if (!playerRef.current) {
      playerRef.current = new window.YT.Player('youtube-player', {
        videoId: embedId,
        playerVars: {
          rel: 0, 
        },
        events: {
          onReady: () => {
            console.log('YouTube Player is ready');
            setPlayerReady(true);
          },
          onError: (event) => {
            console.error('YouTube Player Error:', event);
          },
        },
      });
    }
  };

  useEffect(() => {
    // Load the YouTube IFrame API if it's not already loaded
    if (!window.YT) {
      const script = document.createElement('script');
      script.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(script);
      script.onload = () => {
        initializePlayer();  // Call initializePlayer once the script is loaded
      };
    } else {
      initializePlayer();  // Initialize the player if YT API is already available
    }
  }, [embedId]);

  return { playerRef, playerReady };
};
