import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import "../componentscss/NetworkChart.css";


const NetworkChart = ({selectedNetwork}) => {
    const data = selectedNetwork?.priceHistory.map((price, index) => {
        return {
            name: index,
            price: price
        }
    } )


  return (
    <div className="relative w-full h-72"> {/* Container for chart and text overlays */}
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={data}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#64FFB7" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#64FFB7" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <Tooltip />
        <Area type="monotone" dataKey="price" stroke="#64FFB7" fillOpacity={1} fill="url(#colorUv)" />
      </AreaChart>
    </ResponsiveContainer>

    {/* Overlay for Price */}
    <div className="absolute bottom-[10%] left-3 md:left-6 lg:left-9"> {/* Responsive adjustments for left positioning */}
      <div className="flex flex-col items-start space-y-1"> {/* Vertical alignment for price data */}
        <p className="chart-sub-title-container text-white">Price</p>
        <p className="chart-sub-value-container text-white text-lg font-bold">$ {Number(selectedNetwork.price).toFixed(2)}</p>
      </div>
    </div>

    {/* Overlay for 24H Change */}
    <div className="absolute bottom-[10%] right-3 md:right-6 lg:right-9"> {/* Responsive adjustments for right positioning */}
      <div className="flex flex-col "> {/* Horizontal alignment for change data */}
        <p className="chart-sub-title-container text-white">24H Change</p>
        <p className={`chart-sub-value-container text-lg font-bold ${selectedNetwork['24h'] < 0 ? 'text-red-500' : 'text-green-500'}`}>
          {selectedNetwork['24h']} %
        </p>
      </div>
    </div>
  </div>
  );
};

export default NetworkChart;
