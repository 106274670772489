import { axiosDev } from "../axios/axiosInstances";
import { Notify } from "notiflix";

export const fetchNetworks = async (setNetworks, setNetworksStats, setNetworkRequestFailed) => {
    try {
        const response = await axiosDev.get('/all');
        setNetworks(response.data.networks);
        setNetworksStats(response.data.stats);
    } catch (error) {
        Notify.failure('Failed to load networks');
        setNetworkRequestFailed(true);
        console.error(error);
    }
};

export const fetchNetworkData = async (row, setSelectedNetworkNameAndLogo, setSelectedNetwork, setModalOpen) => {
    try {
        const response = await axiosDev.get(`/status/${row.original.valoper}`);
        setSelectedNetworkNameAndLogo({
            name: row.original.name,
            logo: row.original.logo,
            networkId: row.original.networkId
        });
        setSelectedNetwork(response.data);
        setModalOpen(true);
    } catch (error) {
        Notify.failure('Failed to load network');
        console.error(error);
    }
};