import React from "react";
import emailjs from "emailjs-com";
import { Notify } from "notiflix";
import ClipLoader from "react-spinners/ClipLoader";
import "./Contact.css";

function Contact() {
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = React.useState({
    name: false,
    email: false,
    message: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: false }); 
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation
    const newErrors = {
      name: !formData.name,
      email: !formData.email || !validateEmail(formData.email),
      message: !formData.message,
    };

    if (newErrors.name || newErrors.email || newErrors.message) {
      setErrors(newErrors);
      Notify.failure("Please fill out all fields correctly");
      return;
    }

    setLoading(true);
    emailjs
      .sendForm("service_hc3ao9g", "template_ouj5r0l", e.target, "TELrj2GpU09Bo7FJQ")
      .then(
        (result) => {
          Notify.success("Email sent successfully");
          setFormData({
            name: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          Notify.failure("Failed to send email");
          console.log(error.text);
        }
      ).finally(() => {
        setLoading(false);
      });
    e.target.reset();
  };

  return (
    <div className="h-auto flex justify-center items-center px-4">
      <div className="w-[90%] md:w-[70%]">
        <h1 className="contact-title mb-10 md:mb-20 mt-24 md:mt-32 text-center md:text-left">
          Contact Us!
          <br />
          Get in Touch with the Hunters.
        </h1>
        <div className="gradient-border-box-video-contact grid grid-cols-1 md:grid-cols-2 gap-10">
          <div className="">
            <h2 className="contact-form-title mb-7 text-center md:text-left">
              General inquiries.
            </h2>
            <p className="contact-form-sub-title text-center md:text-left">
              Let us know how we can help and we
              <br />
              will get back to you as soon as possible.
            </p>
          </div>
          <div className="flex flex-col">
            <form onSubmit={handleSubmit} className="flex flex-col">
              <input
                type="text"
                className={`gradient-border-box-video-contact-input mb-7 ${errors.name ? 'error-border' : ''}`}
                placeholder="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              <input
                type="text"
                className={`gradient-border-box-video-contact-input mb-7 ${errors.email ? 'error-border' : ''}`}
                placeholder="Email address"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              <textarea
                className={`gradient-border-box-video-contact-input mb-14 ${errors.message ? 'error-border' : ''}`}
                placeholder="Message"
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
              <button disabled={loading} className="stake-now-button relative bg-dark-blue-background text-custom-orange font-bold uppercase py-3 px-4 inline-block overflow-hidden group text-center">
                <span className="corner top-left"></span>
                <span className="corner top-right"></span>
                <span className="corner bottom-left"></span>
                <span className="corner bottom-right"></span>
                {loading ? <ClipLoader/> : "Submit"}
              </button>
            </form>
          </div>
        </div>

        <div className="my-10 md:my-20 text-center">
          <h2 className="contact-updates-title">Stay up to date!</h2>
          <p className="contact-updates-content-bh">@BlockhuntersOrg</p>
        </div>
        <div className="">
          <h2 className="contact-updates-title text-center">Follow Us</h2>
          <div className="flex justify-center mt-7 mb-32">
            <div className="flex flex-row justify-evenly w-full md:w-1/2 items-center">
              <a
                href="https://twitter.com/blockhuntersOrg"
                className="hover:text-custom-orange cursor-pointer"
              >
                <i className="flaticon flaticon-bh-twitter text-4xl md:text-5xl text-white hover:text-custom-orange"></i>
              </a>
              <a
                href="https://t.me/BlockhuntersAnnouncements"
                className="hover:text-custom-orange cursor-pointer"
              >
                <i className="flaticon flaticon-bh-telegram text-4xl md:text-5xl text-white hover:text-custom-orange"></i>
              </a>
              <a
                href="https://block-hunters.medium.com"
                className="hover:text-custom-orange cursor-pointer"
              >
                <i className="flaticon flaticon-bh-medium-ugly text-4xl md:text-5xl text-white hover:text-custom-orange"></i>
              </a>
              <a
                href="https://discord.com"
                className="hover:text-custom-orange cursor-pointer"
              >
                <i className="flaticon flaticon-bh-discord text-4xl md:text-5xl text-white hover:text-custom-orange"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;