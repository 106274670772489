import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosDev } from "../../axios/axiosInstances";
import { fetchNetworks, fetchNetworkData } from "../../services/networkService";


function Network() {
    const { networkName } = useParams();
    const [network, setNetwork] = useState([]);
    const [networksStats, setNetworksStats] = useState({});
    const [networkRequestFailed, setNetworkRequestFailed] = useState(false);
    
    useEffect(() => {
        const fetchNetwork = async () => {
            const response = await axiosDev.get(`/status/${networkName}`);
            setNetwork(response.data);
        }
        fetchNetwork();
    }, []);


    return (
        <div className="h-auto">
            <h1></h1>
        </div>
    )
}

export default Network;