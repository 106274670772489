import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './VideoPage.css';
import { convertTimestampToSeconds, extractTimestamps } from '../../../utils/video_functions';
import { useYouTubePlayer } from '../../../utils/useYoutubePlayer';
import videoIdList from '../../../internal-data/videoIdList.json';

function VideoPage() {
  const { episodeId } = useParams();
  const navigation = useNavigate();
  const [ videoId, setVideoId] = useState('')
  const [ videoTitle, setVideoTitle ] = useState('');
  const [description, setDescription] = useState('');
  const [timestamps, setTimestamps] = useState([]);
  
  const [isExpanded, setIsExpanded] = useState(false);
  
  useEffect(() => {
    const videoId = videoIdList.filter(video => video.episode === episodeId)[0]?.videoEmbedId
    if (videoId) {
      setVideoId(videoId);
    } else {
      navigation('/not-found');
    }

    }, [episodeId]);
    
  const { playerRef, playerReady } = useYouTubePlayer(videoIdList.filter(video => video.episode === episodeId)[0]?.videoEmbedId); 
  
  useEffect(() => {
    if (videoId) {
      fetchVideoData(videoId);
    }
  }, [videoId]);

  const fetchVideoData = async (videoId) => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${process.env.REACT_APP_API_KEY_YOUTUBE}`
      );
      const data = await response.json();
      if (data.items && data.items.length > 0) {
        const videoDescription = data.items[0].snippet.description;
        const videoTitle = data.items[0].snippet.title;

        const descriptionBeforeChapters = videoDescription.split(/Chapters:|0:00 -|00:00/)[0].trim();
        const extractedTimestamps = extractTimestamps(videoDescription);
        
        setVideoTitle(videoTitle);
        setDescription(descriptionBeforeChapters);
        setTimestamps(extractedTimestamps);
      }
    } catch (error) {
      console.error('Error fetching video data:', error);
    }
  };


  const handleChapterClick = (timestamp) => {
    const timeString = timestamp.split(' ')[0];
    const timeInSeconds = convertTimestampToSeconds(timeString);
    if (playerReady && playerRef.current) {
      playerRef.current.seekTo(timeInSeconds, true);  
    } else {
      console.error('Player is not ready yet!');
    }
  };

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const truncatedDescription = description.slice(0, 150);
  const lastSpaceIndex = truncatedDescription.lastIndexOf(' ');
  const displayDescription = lastSpaceIndex === -1 ? truncatedDescription : truncatedDescription.slice(0, lastSpaceIndex);

  if (!episodeId) {
    return <div>Video not found</div>;
  }

  return (
    <div className="container mx-auto p-4 sm:p-6 lg:p-8 w-full lg:w-3/4 bg-transparent pt-12 sm:pt-16 lg:pt-24">
    {/* Top Section: Video and Title */}
    <div className="gradient-border-box-video p-4 rounded-lg shadow-md">
      <h1 className="text-xl sm:text-2xl lg:text-3xl video-title">{videoTitle}</h1>
      <div className="my-4">
        {/* Video Embed */}
        <div className="w-full h-[40vh] sm:h-[50vh] lg:h-[70vh] bg-blue-300 rounded-lg flex items-center justify-center">
          <div id="youtube-player" className="w-full h-full rounded-lg"></div>
        </div>
      </div>
  
      {/* Expandable Video Description */}
      <div className="video-description">
        <p>{isExpanded ? description : `${displayDescription}`}</p>
        <button
          className="text-custom-orange hover:underline mt-2 uppercase"
          onClick={toggleExpanded}
        >
          {isExpanded ? 'Show Less' : 'Show More'}
        </button>
      </div>
  
      {/* Timestamps Section */}
      {timestamps.length > 0 && (
        <div className="gradient-border-box-video-chapters mt-6 p-4 sm:p-6 rounded-lg shadow-md">
          <h2 className="text-xl sm:text-2xl lg:text-3xl video-chapters">Chapters</h2>
          <ul className="list-disc ml-3 sm:ml-5 mt-2 text-gray-600">
            {timestamps.map((timestamp, index) => (
              <li
                key={index}
                className="cursor-pointer text-blue-500 hover:underline"
                onClick={() => handleChapterClick(timestamp.split(' - ')[0])}
              >
                {timestamp}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  </div>
  );
}

export default VideoPage;