import React from 'react';

function BabylonStaking() {
    return (
        <iframe
            src="https://babylon.bh.rocks/"
            style={{ width: '100%', height: '100vh', border: 'none' }}
        />
    );
}

export default BabylonStaking;