import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { initGA, logPageView } from './analytics';

//pages
import Home from './pages/Home/Home';
import BabylonStaking from './pages/BabylonStaking/BabylonStaking';
import Voices from './pages/Voices/Voices';
import VideoPage from './pages/Voices/VideoPage/VideoPage';
import Contact from './pages/Contact/Contact';
import About from './pages/About/About';
import NotFound from './pages/NotFound/NotFound';
import Network from './pages/Network/Network';

//layouts
import AppLayout from './layouts/AppLayout';

//styles
import "./App.css";

function App() {
  useEffect(() => {
    initGA();
  }, []);

  // Function to track route changes
  const TrackPageView = () => {
    const location = useLocation();

    useEffect(() => {
      logPageView();
    }, [location]);

    return null;
  };
  return (
    <Router>
      <TrackPageView />
      <Routes>
        <Route path="/" element={
          <AppLayout>
          <Home />
          </AppLayout>
        } />
        <Route path="/not-found" element={
          <AppLayout>
            <NotFound />
          </AppLayout>
        } />
        <Route path="/babylon-staking" element={<BabylonStaking />} />
        <Route path="/voices" element={
          <AppLayout>
            <Voices />
          </AppLayout>
        } />
        <Route path="/voices/:episodeId" element={
          <AppLayout>
            <VideoPage />
          </AppLayout>
          } />
        <Route path="/contact-us" element={
          <AppLayout>
            <Contact />
          </AppLayout>
          } />
        {/* <Route path="/about" element={
          <AppLayout>
            <About />
          </AppLayout>
          } /> */}
        <Route path='/network/:networkName' element={
          <AppLayout>
            <Network />
          </AppLayout>
        } /> 
      </Routes>
    </Router>
  )
}

export default App;