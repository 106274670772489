import React, { useState, useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import './Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import NetworksTable from "../../components/HomeComponents/NetworkTable.js";
import NetworkModal from '../../components/HomeComponents/NetworkModal.js';
import NetworkChart from '../../components/HomeComponents/NetworkChart.js';
import { Tooltip } from 'react-tooltip';
import { Link } from 'react-router-dom';
import BHDev from "../../assets/blockhunters-dev-logo.png";
import BHVoices from "../../assets/bh-voices-logo-1.png";
import Loki from "../../assets/loki-bh.png";
import { FloatingDock } from '../../components/ui/floating-dock.js';
import { Menu, MenuItem, ProductItem, HoveredLink } from '../../components/ui/navbar-menu.js';
import {
    IconBrandGithub,
    IconBrandX,
    IconExchange,
    IconHome,
    IconNewSection,
    IconTerminal2,
} from "@tabler/icons-react";
import NewsTicker, { Directions } from 'react-advanced-news-ticker';
import { fetchNetworkData, fetchNetworks } from '../../services/networkService.js';

function Home() {
    const [networks, setNetworks] = useState([]);
    const [networkRequestFailed, setNetworkRequestFailed] = useState(false);
    const [networksStats, setNetworksStats] = useState([]);
    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const [selectedNetworkNameAndLogo, setSelectedNetworkNameAndLogo] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [activeItem, setActiveItem] = useState(null);

    const links = [
        {
            title: "Home",
            icon: (
                <IconHome className="h-full w-full text-neutral-500 dark:text-neutral-300" />
            ),
            href: "/babylon-staking",
        },
        {
            title: "Products",
            icon: (
                <IconTerminal2 className="h-full w-full text-neutral-500 dark:text-neutral-300" />
            ),
            href: "#",
        },
        {
            title: "Components",
            icon: (
                <IconNewSection className="h-full w-full text-neutral-500 dark:text-neutral-300" />
            ),
            href: "#",
        },
        {
            title: "Changelog",
            icon: (
                <IconExchange className="h-full w-full text-neutral-500 dark:text-neutral-300" />
            ),
            href: "#",
        },
        {
            title: "Twitter",
            icon: (
                <IconBrandX className="h-full w-full text-neutral-500 dark:text-neutral-300" />
            ),
            href: "#",
        },
        {
            title: "GitHub",
            icon: (
                <IconBrandGithub className="h-full w-full text-neutral-500 dark:text-neutral-300" />
            ),
            href: "#",
        },
    ];

    const handleScroll = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const ref = useRef(null);

    useEffect(() => {
        fetchNetworks(setNetworks, setNetworksStats, setNetworkRequestFailed);
    }, []);

    const handleOpenModal = (row) => {
        fetchNetworkData(row, setSelectedNetworkNameAndLogo, setSelectedNetwork, setModalOpen);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleStakeLinkAnalyticsClick = () => {
        ReactGA.event({
            category: "User",
            action: "Clicked Stake Now",
            label: `Network: ${selectedNetworkNameAndLogo?.networkId}, Validator: ${selectedNetwork?.valoper}`
        });
    }

    const formatNumber = (num) => {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(2) + 'B';
        }
        if (num >= 1000000) {
            return (num / 1000000).toFixed(2) + 'M';
        }
        if (num >= 1000) {
            return (num / 1000).toFixed(2) + 'K';
        }
        return num;
    };

    return (
        <>
            <div id="home" className="home h-[100vh] flex flex-col justify-between">
                <div className="home-title mt-32 flex flex-col items-center justify-center flex-grow">
                    <div className="text-center">
                        <div className="flex flex-col sm:flex-row items-center justify-center">
                            <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl">Hunting for the best</h1>
                            <p className="text-custom-orange ml-0 sm:ml-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold">stake</p>
                        </div>
                    </div>
                    <div id="nt-example1-container" className='mt-5'>
                        <NewsTicker
                            ref={ref}
                            id="nt-example1"
                            direction={Directions.UP}
                            rowHeight={20}
                            maxRows={1}
                            duration={5000}>
                            <div>BlockHunters Voices Ep. 12: Scott Dykstra, Co-Founder Space and Time<a href="/voices/ep-12">watch now</a></div>
                            <div>BlockHunters Voices episode 11 - BurrBear Team is out. <a href="/voices/ep-11">watch now</a></div>
                            <div>Check out the latest article on BlockHunters Medium. <a target='_blank' href="https://block-hunters.medium.com/">read more</a></div>
                            <div>BlockHunters Voices episode 10 - Yair Cleper, CEO Magma Devs is out. <a href="/voices/ep-10">watch now</a></div>
                            <div>Check out our Babylon Staking page. <a target='_blank' href="/babylon-staking">stake now</a></div>
                        </NewsTicker>
                    </div>
                </div>
                <div className="flex flex-col items-center mb-8 text-xs md:text-sm lg:text-base mt-4">
                    <hr className="border-t-[0.5px] border-white w-48 md:w-96 mt-4" />
                    <a
                        onClick={() => handleScroll('networks')}
                        className="flex flex-row items-center cursor-pointer text-white hover:text-custom-orange"
                    >
                        <i className='mt-5 mr-4 flaticon flaticon-bh-caret-down-squared text-2xl'></i>
                        <p className="all-caps mt-4 opacity-50">SEE THE NETWORKS</p>
                        <i className='mt-5 ml-4 flaticon flaticon-bh-caret-down-squared text-2xl'></i>
                    </a>
                </div>
            </div>

            {/* Networks Section */}
            {networkRequestFailed ? (
                <div className="min-h-[100vh] flex items-center justify-center text-2xl font-bold text-red-500">
                    <img src={Loki} alt="Loki" className='w-96' />
                    Failed to load networks<br />
                    Please try again later
                </div>
            ) : (
                <div id="networks" className="min-h-[100vh] flex flex-col items-center justify-center px-4">
                    <NetworksTable projects={networks} handleOpenModal={handleOpenModal} />
                    <div className="flex flex-col items-center md:flex-row justify-between w-full md:w-[75vw] mt-2 space-y-4 md:space-y-0">
                        <div className="flex flex-row items-center p-1 md:p-2 bg-custom-green-darker rounded-md space-x-2">
                            <FontAwesomeIcon icon={faUserSecret} className="text-black text-2xl" />
                            <p
                                data-tooltip-id='delegators'
                                data-tooltip-content="Total number of delegators"
                                data-tooltip-place='top'
                                className="text-white font-bold text-2xl green-button-value">
                                {networksStats?.delegators}
                            </p>
                            <FontAwesomeIcon icon={faDollarSign} className="text-black text-2xl" />
                            <p
                                data-tooltip-id='tvl'
                                data-tooltip-content="Total value delegated"
                                data-tooltip-place='top'
                                className="text-white font-bold text-2xl green-button-value">
                                {formatNumber(Number(networksStats?.tvl))}
                            </p>
                            <Tooltip id='delegators' />
                            <Tooltip id='tvl' />
                        </div>
                        <div className="flex flex-col items-center md:absolute md:left-1/2 md:transform md:-translate-x-1/2 text-xs md:text-sm lg:text-base">
                            <hr className="border-t-[0.5px] border-white w-48 md:w-96 mt-4" />
                            <a
                                onClick={() => handleScroll('home')}
                                className="flex flex-row items-center cursor-pointer text-white hover:text-custom-orange"
                            >
                                <i className='mt-5 mr-4 flaticon flaticon-bh-caret-up-squared text-2xl'></i>
                                <p className="all-caps mt-4 opacity-50 text-2xl">GO BACK TOP</p>
                                <i className='mt-5 ml-4 flaticon flaticon-bh-caret-up-squared text-2xl'></i>
                            </a>
                        </div>
                    </div>
                </div>
            )}

            {/* Network Modal */}
            <NetworkModal
                width={window.innerWidth <= 768 ? '90vw' : '23vw'}
                isOpen={modalOpen}
                onClose={handleCloseModal}
                titleAndLogo={selectedNetworkNameAndLogo}>
                <div className="">
                    <NetworkChart selectedNetwork={selectedNetwork} />
                    <hr width="90%" className="mx-auto my-5" style={{ background: '#FFFFFF', borderRadius: '22px', opacity: 0.15 }} />
                    <div className="flex flex-col w-[90%] mx-auto mt-2 space-y-2">
                        <div className="">
                            <p className="chart-sub-title">DELEGATED ({selectedNetwork?.symbol})</p>
                            <div className="flex flex-row items-center">
                                <img src={selectedNetworkNameAndLogo?.logo} alt="logo" className="w-5 h-5 mr-2" />
                                <p className="chart-sub-value text-white">{selectedNetwork?.votingPower.toLocaleString()}</p>
                            </div>
                        </div>
                        <div className="">
                            <p className="chart-sub-title">DELEGATED (usd)</p>
                            <div className="flex flex-row items-center">
                                <p className="dollar-symbol w-5 h-5 text-white mr-2">$</p>
                                <p className="chart-sub-value text-white">{(selectedNetwork?.votingPower * (selectedNetwork?.price || 0)).toLocaleString()}</p>
                            </div>
                        </div>
                        <div className="">
                            <div className="flex flex-col">
                                <p className="chart-sub-title">DELEGATORS</p>
                                <p className="chart-sub-value text-white">{selectedNetwork?.delegators}</p>
                            </div>
                        </div>
                        <div className="">
                            <div className="flex flex-col">
                                <p className="chart-sub-title">VALIDATOR RANK</p>
                                <p className="chart-sub-value text-white">{selectedNetwork?.rank}</p>
                            </div>
                        </div>
                    </div>
                    <hr width="90%" className="mx-auto my-5" style={{ background: '#FFFFFF', borderRadius: '22px', opacity: 0.15 }} />
                    <div className="flex flex-row justify-between items-center w-[90%] mx-auto">
                        <div className="flex flex-col">
                            <p className="chart-sub-title">APR</p>
                            <p className="chart-sub-value text-white">{Number(selectedNetwork?.apr).toFixed(2)} %</p>
                        </div>
                        <div></div>
                        <div className="flex flex-col">
                            <p className="chart-sub-title">COMMISSION</p>
                            <p className="chart-sub-value text-custom-green">{Number(selectedNetwork?.commission).toFixed(2)} %</p>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center mt-6">
                    <a 
                    target='blank' 
                    href={`https://restake.app/${selectedNetworkNameAndLogo?.networkId}/${selectedNetwork?.valoper}`} 
                    className="stake-now-button relative w-[90%] bg-dark-blue-background text-custom-orange font-bold uppercase py-3 px-4 inline-block overflow-hidden group text-center"
                    onClick={handleStakeLinkAnalyticsClick}
                    >
                        <span className="corner top-left"></span>
                        <span className="corner top-right"></span>
                        <span className="corner bottom-left"></span>
                        <span className="corner bottom-right"></span>
                        STAKE NOW
                    </a>
                </div>
            </NetworkModal>
        </>
    );
}

export default Home;